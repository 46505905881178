
import AdminNav from '@/components/AdminNav.vue'
import MotifClass from '@/types/class'
import Enrollment from '@/types/enrollment'
import Guardian from '@/types/guardian'
import { defineComponent } from 'vue'

export default defineComponent({
  components: { AdminNav },
  data () {
    return {
      motifClass: {
        courseName: ''
      } as MotifClass,
      enrollments: [] as Enrollment[],
      guardians: [] as Guardian[]
    }
  },
  async mounted () {
    if (!Array.isArray(this.$route.params.id)) {
      const classId = parseInt(this.$route.params.id)
      const response = await this.$api.getClassWithEnrollmentsAndGuardians(classId)
      if (response.class && response.enrollments) {
        this.motifClass = response.class
        this.enrollments = response.enrollments
        this.guardians = response.guardians || []
      }
    }
  },
  methods: {
    impersonateStudent (studentId: number) {
      this.$api.impersonateStart({ studentId: studentId })
    },
    copyEmails () {
      const emails = this.guardians.map(guardian => {
        return `"${guardian.firstName} ${guardian.lastName}" <${guardian.emailAddress}>`
      })
      const uniqueEmailsString = [...new Set(emails)].join(', ')
      navigator.clipboard.writeText(uniqueEmailsString)
    }
  }
})
