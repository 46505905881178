<template>
  <div class="container-fluid mt-3">
    <div class="row">
      <admin-nav />
      <div class="col-lg">
        <div class="container">
          <div class="d-flex justify-content-between">
            <div>
              <h4 class="mb-3">Enrollments in {{ motifClass.courseName }} ({{ motifClass.enrollment }}&nbsp;/&nbsp;{{ motifClass.capacity }})</h4>
            </div>
            <div>
              <button class="btn btn-primary" @click="copyEmails()">Copy E-mails</button>
            </div>
          </div>
          <div class="list-group">
            <div class="list-group-item d-flex align-items-center justify-content-between flex-wrap" v-for="enrollment in enrollments" :key="enrollment.id">
              <span class="mb-3">{{ enrollment.studentName }} ({{ enrollment.status }})</span>
              <div class="flex-grow">
                <router-link class="btn btn-info me-3 mb-3" :to="{ name: 'admin_enrollment', params: { id: enrollment.id } }">Edit</router-link>
                <button @click="impersonateStudent(enrollment.studentId)" class="btn btn-primary mb-3">Access account</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import AdminNav from '@/components/AdminNav.vue'
import MotifClass from '@/types/class'
import Enrollment from '@/types/enrollment'
import Guardian from '@/types/guardian'
import { defineComponent } from 'vue'

export default defineComponent({
  components: { AdminNav },
  data () {
    return {
      motifClass: {
        courseName: ''
      } as MotifClass,
      enrollments: [] as Enrollment[],
      guardians: [] as Guardian[]
    }
  },
  async mounted () {
    if (!Array.isArray(this.$route.params.id)) {
      const classId = parseInt(this.$route.params.id)
      const response = await this.$api.getClassWithEnrollmentsAndGuardians(classId)
      if (response.class && response.enrollments) {
        this.motifClass = response.class
        this.enrollments = response.enrollments
        this.guardians = response.guardians || []
      }
    }
  },
  methods: {
    impersonateStudent (studentId: number) {
      this.$api.impersonateStart({ studentId: studentId })
    },
    copyEmails () {
      const emails = this.guardians.map(guardian => {
        return `"${guardian.firstName} ${guardian.lastName}" <${guardian.emailAddress}>`
      })
      const uniqueEmailsString = [...new Set(emails)].join(', ')
      navigator.clipboard.writeText(uniqueEmailsString)
    }
  }
})
</script>
